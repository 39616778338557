<template>
  <div class="page-wrapper">

    <div class="section main">
      <h1 class="main-heading">#ekipaRAZEM</h1>
      <h3 class="second-heading">Włącz się w nasze działania!</h3>
    </div>
    <div class="section content">
      <h3 class="question">W jaki sposób chcesz się zaangażować?</h3>
      <p class="option option-modal" @click="showModalVolunteer = true"><span class="option-text">Chcę zostać wolontariuszem lub wolontariuszką w kampanii wyborczej.</span></p>
      <p class="option option-modal" @click="showModalBanner = true"><span class="option-text">Mam miejsce na banner na moim ogrodzeniu lub balkonie.</span></p>
      <p class="option"><span class="option-text"><a href="https://dolacz.partiarazem.pl" target="_blank">Dołączam do
        <span class="together">razem</span>!<span class="icon style2 fa-external-link"></span></a></span></p>
      <p class="option"><span class="option-text"><a href="https://wesprzyj.partiarazem.pl/platnosc" target="_blank">Wpłacam na
        <span class="together">razem</span>!<span class="icon style2 fa-external-link"></span></a></span></p>
      <p class="option"><span class="option-text"><a href="https://partiarazem.pl/informacje/newsletter" target="_blank">Chcę otrzymywać
        newsletter.<span class="icon style2 fa-external-link"></span></a></span></p>
      <p class="option"><span class="option-text"><a href="https://okw.partiarazem.pl" target="_blank">Zgłaszam się do Obywatelskiej Kontroli
        Wyborów.<span class="icon style2 fa-external-link"></span></a></span></p>
    </div>

    <!--    Modal about banner -->
    <transition name="fade" appear>
      <div class="modal-overlay"
           v-if="showModalBanner"
           @click="showModalBanner = false"></div>
    </transition>

    <transition name="pop" appear>
      <div class="modal" v-if="showModalBanner">
        <div class="x icon fa-solid fa-close" @click="showModalBanner = false"></div>
        <FormModal formType="banner"></FormModal>
      </div>
    </transition>

    <!--    Modal about volonteering -->
    <transition name="fade" appear>
      <div class="modal-overlay"
           v-if="showModalVolunteer"
           @click="showModalVolunteer = false"></div>
    </transition>

    <transition name="pop" appear>
      <div class="modal" v-if="showModalVolunteer">
        <div class="x icon fa-solid fa-close" @click="showModalVolunteer = false"></div>
        <FormModal formType="volunteer"></FormModal>
      </div>
    </transition>
  </div>

</template>

<script>
import FormModal from "../FormModal";

export default {
  components: {FormModal},
  data() {
    return {
      showModalBanner: false,
      showModalVolunteer: false
    }
  }
}
</script>

<style lang="scss">
.main-heading {
  font-size: var(--fontSize-7);
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-8);
  color: white;
}

.second-heading {
  color: white;
}

.together {
  font-family: var(--fontFamily);
  font-weight: 800;
}

.page-wrapper {
  background: url('../../img/ekiparazem2.jpg?quality=50');
  background-size: cover;
  background-attachment: fixed;
  background-position: 0 -300;
  background-repeat: no-repeat;
}

h3.question {
  margin-bottom: var(--spacing-4);
}

p.option {
  margin: var(--spacing-4) 0;
  display: block;
  color: var(--color-razem);
  font-size: var(--fontSize-2-5);
  border-radius: 10px;
  cursor: pointer;
  //background: rgba(170, 8, 108, 0.83);
  background: white;
  border: 2px solid var(--color-razem);

  &.option-modal {
    padding: var(--spacing-2) var(--spacing-2);
  }

  a {
    text-decoration: none;
    color: var(--color-razem);
    display: block;
    padding: var(--spacing-2) var(--spacing-2);
  }

  &:hover {
    background: var(--color-razem-dark);
    color: white;
    a {
      color: white;
    }
  }
}

.icon {
  padding: var(--spacing-2);
  font-size: var(--fontSize-1);

  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
  }
}


@media (max-width: 1400px) {
  .page-wrapper {
    background-position: 0 -80;
  }
}

@media (max-width: 900px) {
  .page-wrapper {
    background-position: -130 0;
  }

  .main-heading {
    font-size: 11vw;
    margin-bottom: var(--spacing-2);
    margin-top: var(--spacing-8);
    color: white;
  }

  .second-heading {
    font-size: 6vw;
  }

  .section {
    &.content {
      padding: var(--spacing-12) var(--spacing-6) var(--spacing-12);
    }
  }
}

@media (max-width: 500px) {

  h3.question, h2 {
    font-size: 9vw;
    word-break: break-word;
  }

  p.option {
    font-size: var(--fontSize-2);
  }
}


</style>
