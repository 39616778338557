<template>
  <div class="form-banner">
    <h2>{{ formTitle }}</h2>
    <div class="error-message-container" v-if="error.isError">
      <div class="error-message">{{ error.message }}</div>
    </div>
    <div class="row reverse-on-mobile">
      <div class="column form-column" v-if="success">
        <div class="success-message">Dziękujemy za Twoje zgłoszenie! W ciągu kilku dni skontaktujemy się z Tobą.</div>
      </div>
      <div class="column form-column" v-if="!success">
        <Form @submit="submit" :validation-schema="schema" class="form" v-slot="{ meta, values }">

          <div class="input">
            <label for="name">Imię*</label>
            <Field placeholder="Imię" name="name" id="name" autocomplete="given-name"/>
            <ErrorMessage name="name"/>
          </div>
          <div class="input">
            <label for="email">Email*</label>
            <Field placeholder="Email" name="email" id="email" autocomplete="email"/>
            <ErrorMessage name="email"/>
          </div>
          <div class="input">
            <label for="telephone">Telefon</label>
            <Field placeholder="Telefon" name="telephone" id="telephone" autocomplete="tel"/>
            <ErrorMessage name="telephone"/>
          </div>
          <div class="input">
            <label for="voivodeship">Województwo*</label>
            <Field v-slot="{ voivodeshipValue }" name="voivodeship" id="voivodeship" as="select">
              <option v-for="voivodeship in voivodeships" :key="voivodeship" :value="voivodeship" :selected="voivodeshipValue">
                {{ voivodeship }}
              </option>
            </Field>
            <ErrorMessage name="voivodeship"/>
          </div>
          <div class="input">
            <label for="code">Kod pocztowy<b v-if="values['voivodeship'] !== 'zagranica'">*</b></label>
            <Field :disabled="values['voivodeship'] === 'zagranica'" placeholder="Kod" name="code" id="code" autocomplete="code"/>
            <ErrorMessage name="code"/>
          </div>

          <div class="privacy-policy">
            Przed wypełnieniem zgłoszenia zapoznaj się z naszą
            <a href="https://partiarazem.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">polityką
              prywatności</a>.
          </div>


          <div class="input checkbox">
            <Field v-slot="{ field }" name="terms" id="terms" type="checkbox" :value="true">
            </Field>
            <label for="terms">
              Wyrażam zgodę na przetwarzanie danych w celu obsługi zgłoszenia i nawiązania kontaktu z partią Razem. *
            </label>
          </div>

          <ErrorMessage name="terms" class="checkbox-error"/>

          <div class="input checkbox">
            <Field v-slot="{ field }" name="newsletter" id="newsletter" type="checkbox" :value="true">
            </Field>
            <label for="newsletter">
              Wyrażam zgodę na przetwarzanie adresu email i numeru telefonu w celu otrzymywania wiadomości od partii
              Razem, informacji o jej działaniach oraz kampaniach. Zgoda może być wycofana w każdej chwili.
            </label>
          </div>

          <ErrorMessage name="newsletter" class="checkbox-error"/>

          <div class="button-container">
            <button class="button" :class="{ disabled: !meta.valid || sending }">
              <span v-if="sending">WYSYŁANIE...</span>
              <span v-else>WYSYŁAM</span>
            </button>
          </div>
        </Form>

        <div class="recaptcha">
          <p>
            Korzystamy z reCAPTCHA od Google:
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">polityka
              prywatności</a> i <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">warunki
            korzystania</a>.
          </p>
          <vue-recaptcha v-if="loaded" ref="recaptcha" :sitekey="siteKey" size="invisible" @verify="verifyCaptcha" @expired="expiredCaptcha"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import {VueRecaptcha} from 'vue-recaptcha';
import * as yup from 'yup';
import {configure} from 'vee-validate';

import {DateTime} from 'luxon';

configure({
  validateOnInput: true,
});

// Regex needs to match an empty string, so that they pass if the field is left blank
const polishPhone = /^(\d{9})?$/;

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    VueRecaptcha,
  },
  props: ['formType'],
  data() {
    const schema = yup.object()
        .shape({
          email: yup.string()
              .required('Pole wymagane')
              .email('Wprowadź prawidłowy email.'),
          name: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalnie 120 znaków.'),
          voivodeship: yup.string()
              .required('Pole wymagane')
              .max(120, 'Maksymalnie 120 znaków.'),
          code: yup.string()
              .max(20, 'Maksymalnie 20 znaków.')
              .when('voivodeship', {
                is: (value) => value === 'zagranica',
                then: (rule) => rule.nullable().notRequired(),
                otherwise: (rule) => rule.required('Pole wymagane').matches(/^[0-9][0-9]-[0-9][0-9][0-9]$/, {message: "Błędny format."})
              }),
          telephone: yup.string()
              .nullable()
              .notRequired()
              .matches(polishPhone, 'Podaj 9 cyfr.'),
          terms: yup.bool()
              .required('Zgoda na przetwarzanie danych jest wymagana.'),
          newsletter: yup.bool(),
        });

    return {
      schema,
      signatures: 0,
      success: false,
      loaded: false,
      voivodeships: [
        'dolnośląskie',
        'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie', 'zagranica'
      ],
      voivodeshipValue: '',
      siteKey: '',
      sending: false,
      error: {
        isError: false,
        message: 'Błąd serwera! Jeśli będzie się powtarzał, prosimy o kontakt mailowy.'
      },
      values: {},
      token: '',
      people: []
    };
  },
  computed: {
    formTitle() {
      return this.formType === 'banner' ? 'Mam miejsce na banner na moim ogrodzeniu lub balkonie.' : 'Chcę zostać wolontariuszem lub wolontariuszką w kampanii wyborczej.';
    }
  },
  async created() {
    this.siteKey = await fetch('/api/config')
        .then((res) => res.json())
        .then((config) => {
          return config.recaptchaSiteKey;
        })
        .catch(() => {
          console.error('There was a problem with fetching captcha site key');
          return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // fallback to always-passing key
        });
    this.loaded = true;
  },
  methods: {
    async submit(values) {
      this.values = values;
      this.sending = true;
      if (this.token.length === 0) {
        this.$refs.recaptcha.execute();
      } else {
        await this.verifyCaptcha(this.token);
      }
    },
    async verifyCaptcha(token) {
      this.token = token;
      await this.submitForm(this.values, token);
      this.sending = false;
    },
    expiredCaptcha() {
      this.$refs.recaptcha.reset();
    },
    async submitForm(values, token) {
      let url = '';
      if (this.formType === 'banner') {
        url = `/api/banner?recaptchaResponse=${token}`;
      }
      if (this.formType === 'volunteer') {
        url = `/api/volunteer?recaptchaResponse=${token}`;
      }
      await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          }
      )
          .then(response => {
            if (response.ok) {
              this.success = true;
              this.error.isError = false;
            } else {
              this.error.isError = true;
              response.json()
                  .then(error => {
                    this.error.message = error.error;
                  });
            }
          })
          .catch(error => {
            this.error.isError = true;
            this.error.message = 'Problem z wysłaniem formularza. Jeśli błąd będzie się powtarzał, prosimy o kontakt mailowy.';
          });

    },
  }
};

</script>

<style lang="scss">

.grecaptcha-badge {
  visibility: hidden;
}

.error-message-container {
  text-align: center;
  background-color: var(--color-razem-dark);
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-2);
  margin-top: 20px;

  .error-message {
    font-size: var(--fontSize-2);
    color: white;
    font-weight: var(--fontWeight-bold);
  }
}

.success-message {
  font-size: var(--fontSize-2);
  color: black;
  padding-bottom: 90px;
  padding-top: 40px;
}

.column.form-column {
  flex: 65%;
  text-align: center;
}


button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.button-container {
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
  margin-top: var(--spacing-2);
}

.form {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: var(--spacing-8);
  text-align: left;

  .privacy-policy {
    position: relative;
    top: 30px;
  }

  .checkbox-error {
    grid-column-start: 1;
    grid-column-end: 4;
    color: var(--color-razem-dark);
  }

  .input {
    font-size: 1.1rem;
    font-weight: var(--fontWeight-black);
    display: grid;
    gap: 0.3rem;
    position: relative;

    label {
      font-size: var(--fontSize-2);
      text-transform: uppercase;
      font-weight: var(--font-weight-headline);
      color: black;
    }

    span {
      font-size: var(--fontSize-1);
      font-weight: var(--fontWeight-normal);
      position: absolute;
      color: var(--color-razem);
      right: 0;
      top: 5px;
    }

    &.checkbox {
      grid-column-start: 1;
      grid-column-end: 4;
      display: flex;
      align-items: flex-start;

      label {
        text-transform: none;
        font-size: var(--fontSize-1);
        padding-left: var(--spacing-8);
      }

      input {
        width: auto;
        margin-right: 1rem;
        margin-top: .3em;
        //flex: 50px;
        filter: invert(0%) hue-rotate(100deg) brightness(1);

        &, &:focus {
          box-shadow: none;
        }
      }
    }

    select {
      padding: 1rem;
      font-size: 1rem;
      border: none;
      outline: 2px solid var(--color-razem);
      border-radius: 10px;
      width: 100%;
      background: white;

      :active, :focus, :focus-within {
        outline: 4px solid var(--color-razem-dark);
      }
    }

    input {
      padding: 1rem;
      font-size: 1rem;
      border: none;
      outline: 2px solid var(--color-razem);
      border-radius: 10px;
      width: 100%;


      &:disabled {
        background: #e5e5e5;
        outline: 2px solid #a4a4a4;
      }

      &:focus,
      &:active {
        outline: 4px solid var(--color-razem-dark);
        &:disabled {
          outline: 2px solid #a4a4a4;
        }
      }

      &[type="checkbox"] {
        outline: none;
      }
    }
  }
}

.recaptcha {
  text-align: center;
  margin-top: var(--spacing-4);

  p {
    font-size: var(--fontSize-1);
  }
}


@media (max-width: 54rem) {
  .hide-on-mobile {
    display: none;
  }

  .row.reverse-on-mobile {
    flex-direction: column-reverse;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;

    .privacy-policy {
      grid-column-end: 2;
      top: 0;
    }

    .checkbox-error {
      grid-column-end: 2;
    }

    .input {
      &.checkbox {
        grid-column-end: 2;
      }
    }
  }

  .button-container {
    grid-column-end: 2;
  }

}


</style>
