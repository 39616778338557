<template>
  <footer>
    <div class="section">

      <img src="../img/logo-razem.png" class="logo-razem">

      <p>Partia Razem, ul. Nowy Świat 27, 00-029 Warszawa</p>
      <p><a href="mailto:kontakt@partiarazem.pl" target="_blank">kontakt@partiarazem.pl</a></p>
      <p><a href="https://partiarazem.pl" target="_blank">partiarazem.pl</a></p>
      <p><a href="https://partiarazem.pl/informacje/polityka-prywatnosci" target="_blank" class="black">Polityka Prywatności</a></p>

      <div class="icons">
        <a href="https://www.facebook.com/partiarazem" target="_blank" class="icon fa-brands fa-facebook"></a>
        <a href="https://twitter.com/partiarazem" target="_blank" class="icon fa-brands fa-twitter"></a>
        <a href="https://www.instagram.com/partiarazem/" target="_blank" class="icon fa-solid fa-instagram"></a>
        <a href="mailto:kontakt@partiarazem.pl" target="_blank" class="icon fa-solid fa-envelope"></a>
        <a href="https://social.partiarazem.pl/@razem" target="_blank" class="icon fa-brands fa-mastodon"></a>
      </div>

    </div>
  </footer>
</template>

<style lang="scss">
footer {
  text-align: center;
  color: var(--color-gray-600);
  background: var(--color-background-2);

  .logo-razem {
    width: 80px;
    margin: var(--spacing-2);
  }

  p {
    font-size: var(--fontSize-1);
    padding: 0;
    margin: 0;
  }

  a:hover {
    color: var(--color-work-3);
  }

  .icons {
    padding: var(--spacing-4);
    .icon {
      padding: var(--spacing-4);
      font-size: var(--fontSize-2);

      &:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
      }
    }
  }

}
</style>
